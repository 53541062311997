.App {
  text-align: center;
}

.blue-border {
  border: solid 5px blue;
}

.light-blue {
  background: #0a33bb !important;
  color: rgb(149, 228, 241);
}
.arrow {
  position: absolute;
  top: 50%;
  left: -150px;
  width: 200px;
  height: 200px;
  background-color: aqua;
  border-radius: 0 100%  100% 0; 
  transform: translateY(-50%);
  transition: left 0.3s ease;
  z-index: 1;
}

.arrow.open {
  left: 250px;
}
/* .sidebar_wrapper{
  position: sticky;
    top: 0;
    height: 100vh;
    overflow-y: auto;
} */


.sidebar-footer{
  position: absolute;
  bottom: 6rem;
  right: .75rem;
  display: flex;
  justify-content: space-between ;
  align-items: center;
  width: 100%;
  height: 10%;
  background-color: bisque;
}



.login-row{
  height: 100vh;
  width: 100%;

}

.form{
  width: 80%;
}


.register{

        border-right: 3px solid rgb(224, 13, 66);
}

.form-error{
  font-size:medium;
  color: red;
}